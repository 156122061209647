import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import classnames from "classnames";
import LearnMoreTitle from "features/Common/modules/LearnMore/modules/LearnMoreTitle/LearnMoreTitle";
import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./Header.module.css";

export const Header = (props) => {
  const { right, classNames = {}, ...rest } = props;

  return (
    <div
      className={classnames(
        styles.container,
        "d-flex align-items-center justify-content-between font-weight-bold",
        classNames.container
      )}
    >
      <GoBack {...rest} classNames={classNames} />
      {right}
    </div>
  );
};

export const GoBack = ({
  onBackClick,
  title,
  classNames = {},
  hideBackButton,
  learnMoreHref,
  about,
}) => {
  const history = useHistory();

  return (
    <div
      className={classnames(
        "d-flex align-items-center",
        classNames.wrapperClass
      )}
    >
      {hideBackButton ? null : (
        <ArrowBackIcon
          className={styles.backIcon + " pointer"}
          onClick={onBackClick || history.goBack}
        />
      )}

      {learnMoreHref ? (
        // `classNames.title` is not passed, as it is not being used in places implemented till now. Pass it down and handle in future if needed
        <LearnMoreTitle pageTitle={title} href={learnMoreHref} about={about} />
      ) : (
        <div className={classnames(styles.title, classNames.title)}>
          {title}
        </div>
      )}
    </div>
  );
};
